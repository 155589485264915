import React from 'react'
import PropTypes from 'prop-types'
import { useSiteMetadata } from '../SiteData'
import Helmet from 'react-helmet'

const logoImage = '/images/logo/LogSpace_Main_Logo.png';
const logoImage2x = '/images/logo/LogSpace_Main_Logo@2x.png';

const logoImageDark = '/images/logo/LogSpace_Main_Logo_dark_bg.png';
const logoImageDark2x = '/images/logo/LogSpace_Main_Logo_dark_bg@2x.png';

const logoImageWhiteout = '/images/logo/LogSpace_Main_Logo_whiteout.png';
const logoImageWhiteout2x = '/images/logo/LogSpace_Main_Logo_whiteout@2x.png';

const logoImageBlackout = '/images/logo/LogSpace_Main_Logo_blackout.png';
const logoImageBlackout2x = '/images/logo/LogSpace_Main_Logo_blackout@2x.png';

export default function BrandLogo(props) {
	let {eager, logoVariant} = props;

	let {productName, trademarkText} = useSiteMetadata();

	let effectiveLogoImage, effectiveLogoImage2x;
	if(logoVariant === 'normal') {
		effectiveLogoImage = logoImage;
		effectiveLogoImage2x = logoImage2x;
	} else if(logoVariant === 'whiteout') {
		effectiveLogoImage = logoImageWhiteout;
		effectiveLogoImage2x = logoImageWhiteout2x;
	} else if(logoVariant === 'blackout') {
		effectiveLogoImage = logoImageBlackout;
		effectiveLogoImage2x = logoImageBlackout2x;
	} else {
		// darkBg
		effectiveLogoImage = logoImageDark;
		effectiveLogoImage2x = logoImageDark2x;
	}

	// https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content#Including_media
	// https://css-tricks.com/snippets/css/retina-display-media-query/

	return (
		<>
			{eager && (
				<Helmet defer={false}>
					<link as="image" rel="preload" href={effectiveLogoImage} media="(-webkit-max-device-pixel-ratio: 1.24), (max-resolution: 119dpi)"/>
					<link as="image" rel="preload" href={effectiveLogoImage2x} media="(-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)"/>
				</Helmet>
			)}
			<img alt={productName + trademarkText} src={effectiveLogoImage} srcSet={effectiveLogoImage2x + " 2x"}/>
		</>
	);
}
BrandLogo.propTypes = {
	/** If true, preload links will be added to the head for the logo images used. */
	eager: PropTypes.bool,
	logoVariant: PropTypes.oneOf(['normal', 'darkBg', 'whiteout', 'blackout']),
};
