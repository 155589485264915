import React from 'react';
import PropTypes from 'prop-types';
import MLink from "@material-ui/core/Link";
import {CookieConsention} from '../page/CookieBanner';

export default class AnchorLink extends React.Component {
	constructor(props) {
		super(props);
		this.handleLink = this.handleLink.bind(this);
	}

	handleLink(evt, onCookieConsent) {
		let {onClick} = this.props;

		onCookieConsent();

		if (onClick) {
			onClick(evt);
		}
	}

	render() {
		let {href, component, suppressDecoration, className, color = 'primary', children, ...other} = this.props;
		let pathname = href;
		let search = "";
		if (href.pathname) {
			pathname = href.pathname;
			search = href.search || "";
		}

		const Component = component || MLink;
		if (Component === MLink) {
			other = {
				...other,
				underline: !suppressDecoration ? "always" : "hover",
			};
		}

		return (
			<CookieConsention>
				{onCookieConsent => (
					<Component color={color} href={pathname + search} className={className} onClick={evt => { this.handleLink(evt, onCookieConsent); }} {...other}>
						{children}
					</Component>
				)}
			</CookieConsention>
		);
	}
}
AnchorLink.propTypes = {
	/** Either an object containing a "pathname" or a string that is the pathname */
	href: PropTypes.any.isRequired,
	/** Type of component to use, is Material-UI 'Link' if not specified */
	component: PropTypes.elementType,
	/** true to hide normal link decoration */
	suppressDecoration: PropTypes.bool,
	color: PropTypes.oneOf([
		'default',
		'error',
		'inherit',
		'primary',
		'secondary',
		'textPrimary',
		'textSecondary',
	]),
	/** Optional classes to add */
	className: PropTypes.string,
	onClick: PropTypes.func,
	/** The link content */
	children: PropTypes.any.isRequired,
};
