import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

export function useSiteMetadata() {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl,
						productName,
						trademarkText,
						webAppUrl,
						demoAppUrl,
						copyright,
						pricePerSeat,
						googleAnalyticsTrackingId
					}
				}
			}
		`
	);
	return data.site.siteMetadata;
}

export const withSiteData = Comp => {
	return props => (<Comp {...useSiteMetadata()} {...props}/>);
};
