import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

/**
 * Facebook automatically tracks history.pushState events, so we don't need to do anything when the page changes
 * https://developers.facebook.com/ads/blog/post/v2/2017/05/29/tagging-a-single-page-application-facebook-pixel/
 */
export function FacebookPixel() {
	const pixelId = useFacebookPixelId();

	React.useEffect(() => {
		const f = window;
		const b = document;
		const e = 'script';
		const v = 'https://connect.facebook.net/en_US/fbevents.js';
		let n = undefined;
		let t = undefined;
		let s = undefined;
		if (f.fbq) return
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
		}
		if (!f._fbq) f._fbq = n
		n.push = n
		n.loaded = !0
		n.version = '2.0'
		n.queue = []
		t = b.createElement(e)
		t.async = !0
		t.src = v
		s = b.getElementsByTagName(e)[0]
		s.parentNode.insertBefore(t, s)

		f.fbq('init', pixelId);
		f.fbq('track', 'PageView');
	}, []);

	return null;
}

function useFacebookPixelId() {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						facebookPixelId
					}
				}
			}
		`
	);
	return data.site.siteMetadata.facebookPixelId;
}
