// This file is only meant to be used temporarily while converting from Bootstrap to Material-UI

const indexedColors = ["#518", "#3636ec", "#08d7df"];

/**
 * @param idx {number} the zero-based color index to find
 */
export function findColorByIndex(idx) {
	idx = Math.max(0, idx) % indexedColors.length;
	return indexedColors[idx];
}

export function radialGradientStyle(theme, color1, color2) {
	return {
		fallbacks: [{background: "-webkit-radial-gradient(top left,circle," + color2 + " 0%," + color1 + " 100%)"}],
		background: "radial-gradient(circle at top left," + color2 + " 0%," + color1 + " 100%)",
		backgroundColor: color1,
		color: theme.palette.getContrastText(color1),
	};
}
