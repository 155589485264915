import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics'
import { IfCookieConsent } from './page/CookieBanner'
import { SchemaMarkupWebPage } from './common/SchemaMarkup'

export default function PageInfo(props) {
	let {title, description} = props;
	return (
		<>
			<Helmet defer={false}>
				<title>{title}</title>
				{description && (
					<meta name="description" content={description}/>
				)}
			</Helmet>
			<SchemaMarkupWebPage
				name={title}
				description={description}
			/>
			<IfCookieConsent>
				<GoogleAnalytics pageTitle={title}/>
			</IfCookieConsent>
		</>
	);
}
PageInfo.propTypes = {
	/** The HTML page title */
	title: PropTypes.string.isRequired,
	/** The HTML meta content for name "description". Most every page should have this, except for e.g. the 404 page */
	description: PropTypes.string,
};
