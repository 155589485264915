import React from 'react';
import PropTypes from 'prop-types';

/**
 * A component that listens for key press events while it is mounted
 */
export default class KeyPressMonitor extends React.Component {
	componentDidMount() {
		let {onKeydown} = this.props;
		document.addEventListener('keydown', onKeydown);
	}

	componentWillUnmount() {
		let {onKeydown} = this.props;
		document.removeEventListener('keydown', onKeydown);
	}

	render() {
		return null;
	}
}
KeyPressMonitor.propTypes = {
	/** a callback that receives params (event) when a 'keydown' event occurs. This function should be the exact same instance of a listener when the component unmounts. */
	onKeydown: PropTypes.func.isRequired,
};
