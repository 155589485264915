import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useSiteMetadata } from '../SiteData'
import RouterLink from '../shared/RouterLink'
import AnchorLink from '../shared/AnchorLink'
import KeyPressMonitor from '../shared/event/KeyPressMonitor'
import { useClickOutsideListener } from '../shared/event/ReactClickOutside'
import AnchorButton from '../shared/AnchorButton'
import { fade, makeStyles } from '@material-ui/core/styles'
import {findColorByIndex, radialGradientStyle} from '../themeColors'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu';
import { ForceHeadlineDarkTheme, ForceLightTheme } from '../../reused-libraries/materialTheme'
import BrandLogo from '../common/BrandLogo'
import Typography from '@material-ui/core/Typography'

const navBgColorDark = "#181818";
const navBgColorLight = "#fbfbfb";

const useHeaderNavigationStyles = makeStyles(theme => {
	return {
		navWrapper: {
			position: "relative",
		},
		navMain: {
			position: "relative",
			width: "100%",
			"& $navBrandName": {
				"& a, a:hover": {
					margin: "0",
					fontWeight: "200",
					textDecoration: "none",
				}
			},
			"& a, a:hover": {
				margin: "0.75rem 0 0.75rem " + theme.spacing(3) + "px",
				display: "inline-block",
			},
		},
		navOverlayContainer: {
			position: "absolute",
			top: 0,
			left: 0,
			zIndex: 1,
			"&$navigationBg": {
				background: props => fade(props.isDark ? navBgColorDark : navBgColorLight, 0.7),
			},
		},
		navOverlayContainerWideScreen: {
			[theme.breakpoints.up('sm')]: {
				position: "absolute",
				top: 0,
				left: 0,
				zIndex: 1,
				"&$navigationBg": {
					background: props => fade(props.isDark ? navBgColorDark : navBgColorLight, 0.7),
				},
			},
		},
		navigationBg: {
			background: props => props.isDark ? "linear-gradient(10deg, " + findColorByIndex(1) + ", " + findColorByIndex(0) + ")" : navBgColorLight,
		},
		mainContainer: {
			[theme.breakpoints.up('md')]: {
				paddingLeft: theme.spacing(6),
			},
		},
		navBrandName: {
			height: "40px",
			marginTop: theme.spacing(1.5),
			marginBottom: theme.spacing(1.5),
		},
		navigationBgTransparent: {
			backgroundColor: "transparent",
		},
		navSunsetNotice: {
			textAlign: 'center',
			padding: theme.spacing(4),
			backgroundColor: props => props.isDark ? navBgColorDark : navBgColorLight,
		},
		navSunsetImportant: {
			color: theme.palette.error.main,
		},
	};
}, {name: "HeaderNavigation"});

export default function HeaderNavigation(props) {
	let {logoVariant, landingMode, bg, alwaysOverlay, overlayWhenWide} = props;

	let {webAppUrl, demoAppUrl} = useSiteMetadata();

	let isDark = logoVariant === "darkBg" || logoVariant === "whiteout";
	const HeaderTheme = isDark ? ForceHeadlineDarkTheme : (bg ? ForceLightTheme : React.Fragment);

	const classes = useHeaderNavigationStyles({isDark});

	return (
		<HeaderTheme>
			<div className={classes.navWrapper}>
				<Paper
					component="nav"
					square
					className={cn(
						classes.navMain,
						alwaysOverlay ? classes.navOverlayContainer : {[classes.navOverlayContainerWideScreen]: overlayWhenWide},
						bg ? classes.navigationBg : classes.navigationBgTransparent,
					)}
					elevation={!!bg ? 3 : 0}
				>
					<div className={cn("container-fluid", classes.mainContainer)}>
						<Grid container className="align-items-center">
							<Grid item xs="auto" lg className="d-flex align-items-center justify-content-between">
								{!landingMode && (
									<div className="order-1 order-lg-2 text-lg-right text-nowrap">
										<NavMenu demoAppUrl={demoAppUrl}/>
									</div>
								)}
								<div className="order-2 order-lg-1">
									<div className={cn(classes.navBrandName)}>
										<RouterLink to="/">
											<BrandLogo eager logoVariant={logoVariant}/>
										</RouterLink>
									</div>
								</div>
							</Grid>
							{!landingMode && (
								<Grid item xs lg="auto" className="order-3 ml-auto text-right text-nowrap">
									<AnchorButton variant="outlined" color="default" href={webAppUrl + '/login'}>Login</AnchorButton>
								</Grid>
							)}
						</Grid>
					</div>
					<div className={classes.navSunsetNotice}>
						<Typography component="h1" variant="h3">
							<span className={classes.navSunsetImportant}>Important: </span>
							LogSpace is being discontinued.
							<RouterLink to="/blog/discontinuation-of-logspace/">Read this update for details.</RouterLink>
						</Typography>
						<Typography component="p" variant="body2">
							Please export your time logs, expenses, invoices, and any other data you wish to retain before <b>June 7, 2024</b>.
						</Typography>
					</div>
				</Paper>
			</div>
		</HeaderTheme>
	);
}
HeaderNavigation.propTypes = {
	logoVariant: PropTypes.oneOf(['normal', 'darkBg', 'whiteout']),
	landingMode: PropTypes.bool,
	/** true to include a background behind the header, or false for a transparent header */
	bg: PropTypes.bool,
	/** true for a transparent and absolutely positioned nav regardless of screen width */
	alwaysOverlay: PropTypes.bool,
	/** true for a transparent and absolutely positioned nav when the screen is wider */
	overlayWhenWide: PropTypes.bool,
};

const useNavMenuStyles = makeStyles(theme => ({
	navMenu: {
		[theme.breakpoints.down('md')]: {
			zIndex: "5",
			marginTop: "0.3rem",
			position: "absolute",
			// backgroundColor: findColorByIndex(1),
			...radialGradientStyle(theme, findColorByIndex(0), findColorByIndex(1)),
			boxShadow: "0 5px 8px 4px rgba(0,0,0,0.35)",
			border: "1px solid " + theme.palette.text.primary,
			borderRadius: "4px",
			"& > a,a:hover": {
				color: theme.palette.text.primary,
				margin: "1.4rem 1.5rem",
				display: "block",
			},
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	}
}), {name: "NavMenu"});

function NavMenu(props) {
	let {demoAppUrl} = props;
	const [menuOpen, setMenuOpen] = React.useState(false);
	const wrapperRef = React.useRef();

	const classes = useNavMenuStyles();

	function toggleMenu() {
		setMenuOpen(prevOpen => !prevOpen);
	}

	function closeMenu() {
		setMenuOpen(false);
	}

	function onKeydown(e) {
		if (/(27)/.test(e.which)) {
			// Escape was pressed
			toggleMenu();
		}
	}

	/**
	 * Use "capture" instead of "bubble" in case the clicked target was inside the menu when
	 * clicked, but was removed before the "bubble" event. Also, clicking links inside the menu will
	 * then always trigger latter, which will call "closeMenu" instead of "toggleMenu", so a double
	 * toggle isn't an issue.
	 */
	useClickOutsideListener(
		wrapperRef.current,
		React.useCallback(e => {
			if (menuOpen) {
				toggleMenu();
			}
		}, [menuOpen]),
		null
	);

	return (
		<div ref={wrapperRef}>
			{menuOpen && (
				<KeyPressMonitor onKeydown={onKeydown}/>
			)}

			<div className="d-inline-block d-lg-none my-2">
				<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={toggleMenu}>
					<MenuIcon />
				</IconButton>
			</div>

			<div className="position-relative">
				<div className={cn({[classes.navMenu]: menuOpen}, menuOpen ? "d-block" : "d-none", "d-lg-block")}>
					{/*<RouterLink to="/" color="textPrimary" suppressDecoration onClick={closeMenu}>Home</RouterLink>*/}
					{/*<RouterLink to="/about" color="textPrimary" suppressDecoration onClick={closeMenu}>About</RouterLink>*/}
					{/*<RouterLink to="/features/" color="textPrimary" suppressDecoration onClick={closeMenu}>Features</RouterLink/!*>*/}
					{/*<RouterLink to="/pricing/" color="textPrimary" suppressDecoration onClick={closeMenu}>Pricing</RouterLink>*/}
					{/*<RouterLink to="/time-management-101/" color="textPrimary" suppressDecoration onClick={closeMenu}>Time Management 101</RouterLink>*!/*/}
					<AnchorLink href={demoAppUrl + '/app/demonstration'} color="textPrimary" suppressDecoration>Demo</AnchorLink>
				</div>
			</div>
		</div>
	);
}
NavMenu.propTypes = {
	demoAppUrl: PropTypes.string.isRequired,
};

