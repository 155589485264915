import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import AnchorLink from './AnchorLink'

export default function AnchorButton(props) {
	let {href, children, className, ...otherProps} = props;
	return (
		<AnchorLink component={Button} href={href} className={className} {...otherProps}>
			{children}
		</AnchorLink>
	);
}
AnchorButton.propTypes = {
	href: PropTypes.any.isRequired,
	variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
	color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
	fullWidth: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	className: PropTypes.string,
	children: PropTypes.node,
};
