import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSiteMetadata } from '../SiteData'

const useBrandStyles = makeStyles(theme => ({
	trademarkSmall: {
		fontSize: "0.5em",
		verticalAlign: "text-top",
	},
}), {name: "Brand"});

export default function Brand(props) {
	const {productName, trademarkText} = useSiteMetadata();
	const classes = useBrandStyles();
	return (
		<React.Fragment>
			{productName}{trademarkText && (<span className={classes.trademarkSmall}>{trademarkText}</span>)}
		</React.Fragment>
	);
}
