import React from 'react';
import PropTypes from 'prop-types';
import {useTheme} from "@material-ui/core/styles";

export default function Spacing(props) {
	const {
		p, px, py, pl, pr, pt, pb,
		m, mx, my, ml, mr, mt, mb,
		component: Component = 'div',
		className,
		style: styleProp,
		children,
	} = props;

	const theme = useTheme();

	const style = {...styleProp} || {};
	if(p) {
		style.padding = theme.spacing(p);
	}
	if(px) {
		const spacing = theme.spacing(px);
		style.paddingLeft = spacing;
		style.paddingRight = spacing;
	}
	if(py) {
		const spacing = theme.spacing(py);
		style.paddingTop = spacing;
		style.paddingBottom = spacing;
	}
	if(pl) {
		style.paddingLeft = theme.spacing(pl);
	}
	if(pr) {
		style.paddingRight = theme.spacing(pr);
	}
	if(pt) {
		style.paddingTop = theme.spacing(pt);
	}
	if(pb) {
		style.paddingBottom = theme.spacing(pb);
	}

	if(m) {
		style.margin = theme.spacing(m);
	}
	if(mx) {
		const spacing = theme.spacing(mx);
		style.marginLeft = spacing;
		style.marginRight = spacing;
	}
	if(my) {
		const spacing = theme.spacing(my);
		style.marginTop = spacing;
		style.marginBottom = spacing;
	}
	if(ml) {
		style.marginLeft = theme.spacing(ml);
	}
	if(mr) {
		style.marginRight = theme.spacing(mr);
	}
	if(mt) {
		style.marginTop = theme.spacing(mt);
	}
	if(mb) {
		style.marginBottom = theme.spacing(mb);
	}

	return (<Component style={style} className={className}>{children}</Component>);
}
Spacing.propTypes = {
	p: PropTypes.number,
	px: PropTypes.number,
	py: PropTypes.number,
	pl: PropTypes.number,
	pr: PropTypes.number,
	pt: PropTypes.number,
	pb: PropTypes.number,
	m: PropTypes.number,
	mx: PropTypes.number,
	my: PropTypes.number,
	ml: PropTypes.number,
	mr: PropTypes.number,
	mt: PropTypes.number,
	mb: PropTypes.number,
	component: PropTypes.elementType,
	className: PropTypes.string,
	style: PropTypes.any,
	children: PropTypes.node,
};
