import React from 'react';
import PropTypes, {instanceOf} from 'prop-types';
import {withCookies, Cookies} from 'react-cookie';
import cn from "classnames";

const cookieName = 'CookieConsent';

/** If this is false, it implies consent even in the absence of a cookie, so it should only be set false in those circumstances. */
let cookieConsentRequired = true;

/**
 * Call this method to indicate that implicit consent is given (such as if the user has logged into a member area, etc.)
 *
 * This method will disable the cookie consent mechanism by hiding the banner and disabling creating of more cookie consent cookies.
 */
export function disableCookieConsentRequirement() {
	cookieConsentRequired = false;
}

function consent(cookies, hide = true) {
	if(hide) {
		disableCookieConsentRequirement();
	}
	cookies.set(cookieName, 'true', {path: '/', expires: new Date('2099-01-01T00:00:00Z')});
}

class _CookieBanner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showBanner: false, // set to true once javascript has had a chance to enable it. Static HTML won't show the banner.
		};
		this.isConsented = this.isConsented.bind(this);
		this.onConsent = this.onConsent.bind(this);
		this.onScroll = this.onScroll.bind(this);

		if(this.isConsented()) {
			disableCookieConsentRequirement();
		}
	}

	componentDidMount() {
		if (cookieConsentRequired && !this.isConsented()) {
			window.addEventListener('scroll', this.onScroll, { passive: true });
			this.setState({showBanner: true});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll);
	}

	isConsented() {
		let {cookies} = this.props;
		return "true" === cookies.get(cookieName);
	}

	onConsent(hide) {
		let {cookies} = this.props;
		consent(cookies, hide);
	}

	onScroll(e) {
		let scrollPos = window.scrollY;
		if (scrollPos >= 200) {
			// Gain consent if they scrolled some amount
			this.onConsent(false);
			window.removeEventListener('scroll', this.onScroll);
		}
	}

	render() {
		let {privacyPolicyLink, alwaysShow, className} = this.props;
		let {showBanner} = this.state;
		return (alwaysShow || (showBanner && cookieConsentRequired)) && (
			<BannerContent
				onClick={this.onConsent}
				privacyPolicyLink={privacyPolicyLink}
				className={className}
			/>
		);
	}
}
_CookieBanner.propTypes = {
	cookies: instanceOf(Cookies).isRequired,
	privacyPolicyLink: PropTypes.string,
	alwaysShow: PropTypes.bool,
	className: PropTypes.string,
};

const CookieBanner = withCookies(_CookieBanner);
CookieBanner.propTypes = {
	privacyPolicyLink: PropTypes.string,
	alwaysShow: PropTypes.bool,
	className: PropTypes.string,
};
export default CookieBanner;

function BannerContent(props) {
	let {onClick, privacyPolicyLink, className} = props;
	return (
		<div className={cn("tt-cookie-banner", className)}>
			<div className="mx-3">
				We use cookies to enable security features, enhance usability, and to track usage and advertising interactions for marketing purposes such as via Google Analytics.
				To consent, keep using this website or hit OK.
				{' '}
				{privacyPolicyLink && (
					<React.Fragment>
						<a href={privacyPolicyLink} className="text-nowrap" target="_blank" rel="noopener noreferrer">Learn More</a>
						{' '}
					</React.Fragment>
				)}
			</div>
			<button type="button" className="mx-3" onClick={onClick}>
				OK
			</button>
		</div>
	);
}
BannerContent.propTypes = {
	onClick: PropTypes.func.isRequired,
	privacyPolicyLink: PropTypes.string,
	className: PropTypes.string,
};

/**
 * Renders children only if cookie consent is present, which implies that either we have a cookie consent cookie,
 * or that cookie consent has been disabled because the user has done something that implies consent such as being logged
 * in, etc.
 */
export const IfCookieConsent = withCookies((props) => (
	("true" === props.cookies.get(cookieName) || !cookieConsentRequired) && (props.children || null)
));

/**
 * Allows children to use the onCookieConsent() function to instruct the app to grant cookie consent as a result of
 * the user's action such as clicking a button.
 */
export const CookieConsention = withCookies((props) => {
	let {children: renderConsent, cookies} = props;
	let onCookieConsent = () => {
		if (cookieConsentRequired) {
			consent(cookies);
		}
	};
	return (
		renderConsent(onCookieConsent) || null
	);
});
CookieConsention.propTypes = {
	/** Called with params (onCookieConsent:func) to render content with an action which may provide cookie consent */
	children: PropTypes.func.isRequired,
};
