import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import { useSiteMetadata } from '../SiteData'
import { useLocation } from '@reach/router'
import { useGeneralBlogPostPublishingDates, useSupplementalBlogPageDataForCurrPath } from '../ArticleData'

export function SchemaMarkupRoot(props) {
	return (<>
		<SchemaMarkupOrganization/>
		<SchemaMarkupWebApplication/>
		<SchemaMarkupMobileApplication/>
	</>);
}

/**
 * @return the current URL
 */
function useUrl() {
	const {siteUrl} = useSiteMetadata();
	const location = useLocation();
	const {pathname} = location;
	return siteUrl + pathname;
}

const momentSoftwareOrganization = {
	"@type": "Organization",
	name: "Moment Software LLC",
	legalName: "Moment Software LLC",
	description: "Moment Software LLC is a company that develops custom software and mobile apps.",
	url: "https://moment-software.com/",
	logo: "https://logspace.com/images/markup/moment-software-llc-logo-square.png",
	foundingDate: "2011-07-17",
	sameAs: [
		"https://www.facebook.com/MomentSoftwareLLC",
		"https://www.linkedin.com/company/moment-software-llc",
	],
};

const logSpaceSameAs = [
	"https://www.facebook.com/LogSpaceSoftware",
	"https://www.linkedin.com/company/logspace",
	"https://twitter.com/theLogSpace",
	"https://www.youtube.com/channel/UChsTaD0ZMSXe5oAPf1v8knw",
];

function SchemaMarkupOrganization(props) {
	return (
		<Helmet
			script={[
				helmetJsonLdProp({
					"@context": "http://schema.org",
					"@type": "Organization",
					name: "LogSpace",
					legalName: "LogSpace",
					description: "LogSpace offers an app to keep track of work hours and expenses, as well as send automatic invoices.",
					url: "https://logspace.com/",
					logo: "https://logspace.com/images/markup/logspace-logo-square.png",
					foundingDate: "2017-01-07", // This is when we started the time-tracker repository. We gained the logspace.com domain on 2019-01-24
					address: {
						"@type": "PostalAddress",
						streetAddress: "4145 Belt Line Rd, Ste 212-337",
						addressLocality: "Addison",
						addressRegion: "Texas",
						postalCode: "75001",
						addressCountry: "USA",
					},
					parentOrganization: momentSoftwareOrganization,
					sameAs: logSpaceSameAs,
					contactPoint: [
						{
							"@type": "ContactPoint",
							contactType: "Customer Service",
							url: "https://momentsoftware.zendesk.com/hc/en-us/requests/new",
						}
					],
				}),
			]}
		/>
	);
}

function SchemaMarkupWebApplication(props) {
	return (
		<Helmet
			script={[
				helmetJsonLdProp({
					"@context": "http://schema.org",
					"@type": "WebApplication",
					name: "LogSpace",
					applicationCategory: "BusinessApplication",
					applicationSubCategory: "UtilitiesApplication",
					datePublished: "2019-01-24",
					description: "A free application to track time and expenses on projects. On a team plan, you can send automatic invoices.",
					keywords: "Time, Expenses, Invoicing, Time Tracker, Time Tracking, Time Log, Timesheet, Time Manager, Hours, Team, Clock In",
					url: "https://logspace.com/",
					operatingSystem: "Web App, Windows, macOS, Linux, iOS, Android",
					isAccessibleForFree: true,
// TODO "about", "image", "screenshot"?
					offers: {
						"@type": "Offer",
						"price": "0",
						"priceCurrency": "USD"
					},
// TODO Google says we need "aggregateRating" or "review": https://developers.google.com/search/docs/data-types/software-app#structured-data-type-definitions
					featureList: "https://logspace.com/features/",
					softwareHelp: {
						"@type": "WebPage",
						url: "https://momentsoftware.zendesk.com/hc/en-us",
					},
					author: momentSoftwareOrganization,
					sameAs: logSpaceSameAs,
				}),
			]}
		/>
	);
}

function SchemaMarkupMobileApplication(props) {
	return (
		<Helmet
			script={[
				helmetJsonLdProp({
					"@context": "http://schema.org",
					"@type": "MobileApplication",
					name: "LogSpace - Mobile Time Tracking",
					applicationCategory: "BusinessApplication",
					applicationSubCategory: "UtilitiesApplication",
					datePublished: "2019-04-02",
					description: "Record time spent with a single tap. Your data is stored in the cloud, so you can also access it from the web application to send invoices.",
					keywords: "Time, Invoicing, Time Tracker, Time Tracking, Time Log, Timesheet, Time Manager, Hours, Team, Clock In",
					url: "https://logspace.com/",
// TODO "downloadUrl", "thumbnailUrl"
					operatingSystem: "iOS, Android",
					isAccessibleForFree: true,
// TODO "about", "image", "screenshot"?
					offers: {
						"@type": "Offer",
						"price": "0",
						"priceCurrency": "USD"
					},
// TODO Google says we need "aggregateRating" or "review": https://developers.google.com/search/docs/data-types/software-app#structured-data-type-definitions
					featureList: "https://logspace.com/features/",
					author: momentSoftwareOrganization,
					sameAs: [
						"https://itunes.apple.com/us/app/logspace/id1457386667?ls=1&mt=8",
						"https://play.google.com/store/apps/details?id=com.momentsoftware.logspace",
					],
				}),
			]}
		/>
	);
}

export function SchemaMarkupWebPage(props) {
	let {name, description} = props;

	const url = useUrl();

	return (
		<Helmet
			script={[
				helmetJsonLdProp({
					"@context": "http://schema.org",
					"@type": "WebPage",
					name: name,
					description: description,
					url: url,
// TODO breadcrumb. That might be embedded into an actual, visible breadcrumb though
				}),
			]}
		/>
	);
}
SchemaMarkupWebPage.propTypes = {
	name: PropTypes.string.isRequired,
	description: PropTypes.string,
};

/**
 * Schema markup for the main blog page
 */
export function SchemaMarkupBlog(props) {
	let {headline, description} = props;

	const url = useUrl();
	const {datePublished, dateModified} = useGeneralBlogPostPublishingDates();

	return (
		<Helmet
			script={[
				helmetJsonLdProp({
					"@context": "http://schema.org",
					"@type": "Blog",
					name: headline,
					headline: headline,
					description: description,
					url: url,
					// thumbnailURL: thumbnailURL,
					// image: thumbnailURL,
					datePublished: datePublished,
					dateModified: dateModified,
				}),
			]}
		/>
	);
}
SchemaMarkupBlog.propTypes = {
	headline: PropTypes.string.isRequired,
	description: PropTypes.string,
};

/**
 * Schema markup for an article that is listed on the blog pages
 */
export function SchemaMarkupArticleAsSupplementalBlog(props) {
	const articleData = useSupplementalBlogPageDataForCurrPath();
	const {title, previewImage, date, lastModified} = articleData;

	const localThumbnailUri = previewImage ? previewImage.childImageSharp.gatsbyImageData.src : undefined;

	return (
		<SchemaMarkupArticle
			headline={title}
			localThumbnailUri={localThumbnailUri}
			datePublished={date}
			dateModified={lastModified}
		/>
	);
}

/**
 * Schema markup for an article, blog post, etc.
 */
export function SchemaMarkupArticle(props) {
	let {headline, description, isBlogPost, localThumbnailUri, datePublished, dateModified} = props;

	const {siteUrl} = useSiteMetadata();
	const url = useUrl();
	const thumbnailURL = localThumbnailUri ? siteUrl + localThumbnailUri : undefined;

	return (
		<Helmet
			script={[
				helmetJsonLdProp({
					"@context": "http://schema.org",
					"@type": isBlogPost ? "BlogPosting" : "Article",
					name: headline,
					headline: headline,
					description: description,
					url: url,
					thumbnailURL: thumbnailURL,
					image: thumbnailURL,
					datePublished: datePublished,
					dateModified: dateModified,
// TODO articleSection, articleBody, author, publisher
				}),
			]}
		/>
	);
}
SchemaMarkupArticle.propTypes = {
	headline: PropTypes.string.isRequired,
	description: PropTypes.string,
	/** true if this is not only an article, but also a blog post */
	isBlogPost: PropTypes.bool,
	/** A local URI of a thumbnail image for the article, such as "/static/66459f417676116fa6ccad832be7783d/73c85/csv_invoice_export_systems.png" */
	localThumbnailUri: PropTypes.string,
	/** An ISO-date of when the article was first published, such as "2020-04-05" or "2020-04-05T17:06:20Z" */
	datePublished: PropTypes.string,
	/** An ISO-date of when the article was most recently modified, such as "2020-04-05" or "2020-04-05T17:06:20Z" */
	dateModified: PropTypes.string,
};

export function SchemaMarkupVideo(props) {
	let {name, url, description, thumbnailURL, uploadDate, duration} = props;
	return (
		<Helmet
			script={[
				helmetJsonLdProp({
					"@context": "http://schema.org",
					"@type": "VideoObject",
					name: name,
					description: description,
					url: url,
					contentUrl: url,
					thumbnailURL: thumbnailURL,
					image: thumbnailURL,
					uploadDate: uploadDate,
					duration: duration,
				}),
			]}
		/>
	);
}
SchemaMarkupVideo.propTypes = {
	name: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	/** A description of the video */
	description: PropTypes.string.isRequired,
	/** A URL of a high quality thumbnail image for the video. Preferred formats: JPG, PNG. Size: From 160x90 to 1920x1080 pixels */
	thumbnailURL: PropTypes.string.isRequired,
	/** An ISO-date of when the video was uploaded, such as "2020-04-05" or "2020-04-05T17:06:20Z" */
	uploadDate: PropTypes.string.isRequired,
	/** A duration in ISO-date format, such as "PT1M10S" */
	duration: PropTypes.string,
};

// TODO type "Event"
// TODO add markup to every page? E.g. "HowTo". Google doesn't seem to care about "WebPage" or "AboutPage" here: https://search.google.com/test/rich-results
// TODO type "WebSite" with e.g. {"@context": "http://schema.org", "@type": "WebPage", name: "LogSpace", url: "https://logspace.com/", potentialAction: {"@type": "SearchAction", target: "https://logspace.com/?s={search_term}", "query-input": "required name=search_term"}}
// TODO should we use type "Product"? That sounds like it applies to "service" as well, such as "an episode of a TV show streamed online"
