import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Location } from '@reach/router';

export default function GoogleAnalytics(props) {
	let {pageTitle} = props;

	let googleAnalyticsTrackingId = useGoogleAnalyticsTrackingId();
	return (
		<Location>{locationProps => {
			let {location} = locationProps;
			let {pathname} = location;
			return (
				<GoogleAnalyticsRunner
					googleAnalyticsTrackingId={googleAnalyticsTrackingId}
					pageTitle={pageTitle}
					pagePath={pathname}
				/>
			);
		}}</Location>
	);
}
GoogleAnalytics.propTypes = {
	/** The HTML page title */
	pageTitle: PropTypes.string.isRequired,
};

export function useGoogleAnalyticsTrackingId() {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						googleAnalyticsTrackingId
					}
				}
			}
		`
	);
	return data.site.siteMetadata.googleAnalyticsTrackingId;
}

function gtag() {
	if (typeof document !== 'undefined') {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(arguments);
	}
}

gtag('js', new Date());

class GoogleAnalyticsRunner extends React.Component {
	componentDidMount() {
		let {googleAnalyticsTrackingId, pageTitle, pagePath} = this.props;
		gtag('config', googleAnalyticsTrackingId, {
			'page_title': pageTitle,
			'page_path': pagePath
		});
	}

	render() {
		return null;
	}
}
GoogleAnalyticsRunner.propTypes = {
	googleAnalyticsTrackingId: PropTypes.string.isRequired,
	/** The HTML page title */
	pageTitle: PropTypes.string.isRequired,
	/** The HTML path portion of the location such as "/features/invoicing". This value must start with a slash (/) character. */
	pagePath: PropTypes.string.isRequired,
};
