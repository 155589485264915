import React from 'react'
import PropTypes from 'prop-types'
import { useSiteMetadata } from '../SiteData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RouterLink from '../shared/RouterLink'
import AnchorLink from '../shared/AnchorLink'
import cn from 'classnames';
import Brand from '../shared/Brand'
import { makeStyles } from '@material-ui/core/styles'
import {findColorByIndex, radialGradientStyle} from '../themeColors'
import Grid from '@material-ui/core/Grid'

const useFooterStyles = makeStyles(theme => ({
	footer: {
		...radialGradientStyle(theme, findColorByIndex(0), findColorByIndex(1)),
		color: "#fff",
		padding: "2rem 1rem",
		"& > *:not(:last-child)": {
			marginBottom: "1rem",
		},
		[theme.breakpoints.down('sm')]: {
			"& li": {
				paddingTop: "0.4rem",
				paddingBottom: "0.9rem",
			},
		},
		"& a": {
			color: "#fbfbfb",
		},
	},
	sitemap: {
		fontSize: "0.9em",
		padding: "0 3rem",
		"& > *": {
			padding: "0 3rem !important",
		},
		"& h4": {
			fontSize: "1.2em",
		},
		"& ul": {
			listStyleType: "none",
			paddingLeft: "0",
		},
	},
	footerContent: {
		[theme.breakpoints.down('sm')]: {
			paddingTop: "2rem",
			paddingBottom: "1.6rem",
		}
	},
	legalFooterText: {
		textAlign: "center",
		maxWidth: "1000px",
		fontSize: "0.8em",
		color: "#d2d2d2",
	},
}), {name: "Footer"});

export default function FooterNavigation(props) {
	let {landingMode, children} = props;
	let {webAppUrl, demoAppUrl, copyright} = useSiteMetadata();
	const classes = useFooterStyles();
	return (
		<div className={classes.footer}>
			<div className="container-fluid">
				<Grid container component="nav" spacing={3} className={classes.sitemap}>
					{!!landingMode ? (
						<Grid item xs>
							<div className="mb-4 text-center">
								<RouterLink to="/legal/privacy/" suppressDecoration>Privacy Policy</RouterLink>
							</div>
							<div className="mb-4 text-center">
								<RouterLink to="/legal/tos/" suppressDecoration>Terms of Service &amp; EULA</RouterLink>
							</div>
						</Grid>
					) : (
						<React.Fragment>
							<Grid item xs>
								<h4><Brand/></h4>
								<ul>
									<li><RouterLink to="/" suppressDecoration>Home</RouterLink></li>
									<li><AnchorLink href={demoAppUrl + '/app/demonstration'} suppressDecoration>Demo</AnchorLink></li>
									<li><AnchorLink href={webAppUrl + '/login'} suppressDecoration>Login</AnchorLink></li>
								</ul>
							</Grid>
							<Grid item xs>
								<h4>Information</h4>
								<ul>
									<li><RouterLink to="/features/" suppressDecoration>Features</RouterLink></li>
									<li><RouterLink to="/about/" suppressDecoration>About</RouterLink></li>
									<li><RouterLink to="/pricing/" suppressDecoration>Pricing</RouterLink></li>
									{/*<li><RouterLink to="/time-management-101/" suppressDecoration>Time Management 101</RouterLink></li>*/}
									<li><RouterLink to="/blog/" suppressDecoration>Blog</RouterLink></li>
								</ul>
							</Grid>
							<Grid item xs>
								<h4>Support</h4>
								<ul>
									<li><AnchorLink href="https://momentsoftware.zendesk.com/hc/en-us" suppressDecoration target="_blank" rel="noopener noreferrer">Help Center</AnchorLink></li>
									<li><AnchorLink href="https://momentsoftware.zendesk.com/hc/en-us/requests/new" suppressDecoration target="_blank" rel="noopener noreferrer">Contact us</AnchorLink></li>
								</ul>
							</Grid>
							<Grid item xs md="auto">
								<h4>Legal</h4>
								<ul>
									<li><RouterLink to="/legal/privacy/" suppressDecoration>Privacy Policy</RouterLink></li>
									<li><RouterLink to="/legal/tos/" suppressDecoration>Terms of Service &amp; EULA</RouterLink></li>
								</ul>
							</Grid>
						</React.Fragment>
					)}
				</Grid>
			</div>
			<footer className="container-fluid">
				<div className={cn(classes.legalFooterText, "mx-auto", !!landingMode ? "" : "mt-5")}>
					<p>
						Apple, the Apple&nbsp;logo, and iPhone are trademarks of Apple&nbsp;Inc., registered in the U.S. and other countries and regions.
						App&nbsp;Store is a service mark of Apple&nbsp;Inc.
						{' '}{/*Google&nbsp;Play and the Google&nbsp;Play&nbsp;logo are trademarks of Google&nbsp;LLC.*/}
						Amazon&nbsp;Web&nbsp;Services, the “Powered by AWS” logo, and AWS are trademarks of Amazon.com, Inc. or its affiliates in the U.S. and/or other countries.
						QuickBooks is a trademark of Intuit Inc., registered in the U.S. and other countries.
						Xero is a trademark of Xero Limited.
					</p>
					<p>{copyright}</p>
				</div>
			</footer>
			{children}
		</div>
	);
}
FooterNavigation.propTypes = {
	landingMode: PropTypes.bool,
	children: PropTypes.any,
};
