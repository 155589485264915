import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from '@reach/router'

/**
 * Find the latest post from either blog posts or supplemental blog posts
 * @return {null|{path:string,title:string,previewText:string,previewImage:object|null}}
 */
export function useHeadlinePost() {
	const data = useArticleData();

	const latestBlogPostFm = data.latestBlogPost.edges[0].node.frontmatter;
	const latestBlogPost = !latestBlogPostFm ? null : {...latestBlogPostFm, path: `/blog/${latestBlogPostFm.label}/`};
	const latestSupplementalPost = data.latestSupplementalBlogPost.edges[0].node;
	const compareResult = compareObjects(latestBlogPost, latestSupplementalPost, post => new Date(post.date).valueOf());
	return compareResult > 0 ? latestBlogPost : latestSupplementalPost;
}

/**
 * Find all blog posts and supplemental blog posts including their path, ordered by their publish date descending, plus the total count
 * @return {{posts:array, totalBlogPosts:number}}
 */
export function useAllBlogPostsAndTotals() {
	const {allBlogPosts, allSupplementalBlogPosts} = useArticleData();

	let totalBlogPosts = allBlogPosts.totalCount;

	let posts = allBlogPosts.edges.map(({node}) => {
		let {frontmatter} = node;
		let {label, title, description, previewText, previewImage, date, lastModified, author} = frontmatter;
		return {
			// label: label,
			path: `/blog/${label}/`,
			title: title,
			description: description,
			previewText: previewText,
			previewImage: previewImage,
			author: author,
			date: date,
			lastModified: lastModified,
		};
	});

	let supplementalPosts = allSupplementalBlogPosts.edges.map(({node}) => {
		let {path, title, description, previewText, previewImage, date, lastModified, author} = node;
		return {
			path: path,
			title: title,
			description: description,
			previewText: previewText,
			previewImage: previewImage,
			author: author,
			date: date,
			lastModified: lastModified,
		};
	});

	posts = posts.concat(supplementalPosts).sort((a, b) => compareObjects(b, a, post => new Date(post.date).valueOf()));
	totalBlogPosts += supplementalPosts.length;

	return {posts, totalBlogPosts};
}

/**
 * Find supplemental blog page data for the current path
 */
export function useSupplementalBlogPageDataForCurrPath() {
	const location = useLocation();
	const {pathname} = location;

	const data = useArticleData();

	return React.useMemo(() => {
		const nodes = data.allSupplementalBlogPosts.edges
			.map(edge => edge.node)
			.filter(node => node.path === pathname);
		return nodes && nodes.length === 1 ? nodes[0] : null;
	}, [pathname]);
}

/**
 * Find the earliest publish date, and the latest modification date (including publish dates) for any blog post or supplemental blog post article
 * @return {{datePublished: isoDate|null, dateModified: isoDate|null}}
 */
export function useGeneralBlogPostPublishingDates() {
	const {posts} = useAllBlogPostsAndTotals();
	return React.useMemo(() => {
		let publishedDates = posts.map(post => post.date);
		let modifiedDates = posts.map(post => post.lastModified);
		let datePublished = findEarliestDate(publishedDates);
		let dateModified = findLatestDate(modifiedDates.concat(publishedDates));
		return {datePublished, dateModified};
	}, []);
}

function findEarliestDate(isoDates) {
	return findDate(isoDates, (a, b) => compareObjects(a, b, d => new Date(d).valueOf()));
}

function findLatestDate(isoDates) {
	return findDate(isoDates, (a, b) => compareObjects(b, a, d => new Date(d).valueOf()));
}

function findDate(isoDates, comparator) {
	let date = null;
	// Remove null dates
	isoDates = isoDates.filter(d => !!d);
	if (isoDates.length > 0) {
		isoDates = isoDates.sort(comparator);
		date = isoDates[0];
	}
	return date;
}

/**
 * @param obj1 {object|null} the first object
 * @param obj2 {object|null} the second object
 * @param numberMapper {function} called with a non-null object and converts it to either null or a number
 * @return {number} a non-null number where a negative, zero, or positive result indicates the first object is less than, equal to, or greater than the second object. Nulls are considered "less".
 */
function compareObjects(obj1, obj2, numberMapper) {
	return !obj1 ? (obj2 ? -1 : 0) : (obj2 ? compareNumbers(numberMapper(obj1), numberMapper(obj2)) : 1);
}

/**
 * Compare numbers, which may be null
 * @param num1 {number|null}
 * @param num2 {number|null}
 * @return {number} a non-null number where a negative, zero, or positive result indicates the first number is less than, equal to, or greater than the second number. Nulls are considered "less".
 */
function compareNumbers(num1, num2) {
	return !num1 ? (num2 ? -1 : 0) : (num2 ? num1 - num2 : 1);
}

/**
 * Find the latest blog post and the latest article that is a supplemental blog post
 */
function useArticleData() {
	return useStaticQuery(
		graphql`{
  latestBlogPost: allMarkdownRemark(filter: {fields: {slug: {regex: "/^/blog-content/.*/"}}}, sort: {fields: frontmatter___date, order: DESC}, limit: 1) {
    edges {
      node {
        frontmatter {
          label
          title
          previewText
          previewImage { childImageSharp { gatsbyImageData(width: 700, placeholder: BLURRED, layout: CONSTRAINED) } }
          date
        }
      }
    }
  }
  latestSupplementalBlogPost: allSupplementalBlogPagesYaml(sort: {fields: date, order: DESC}, limit: 1) {
    edges {
      node {
        path
        title
        previewText
        previewImage { childImageSharp { gatsbyImageData(width: 700, placeholder: BLURRED, layout: CONSTRAINED) } }
        date
      }
    }
  }
  allBlogPosts: allMarkdownRemark(filter: {fields: {slug: {regex: "/^/blog-content/.*/"}}}, sort: {fields: frontmatter___date, order: ASC}) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          label
          title
          description
          previewText
          previewImage { childImageSharp { gatsbyImageData(width: 700, placeholder: BLURRED, layout: CONSTRAINED) } }
          date
          lastModified
          author
        }
        excerpt
      }
    }
  }
  allSupplementalBlogPosts: allSupplementalBlogPagesYaml(sort: {fields: date, order: ASC}) {
    edges {
      node {
        id
        path
        title
        previewText
        previewImage { childImageSharp { gatsbyImageData(width: 700, placeholder: BLURRED, layout: CONSTRAINED) } }
        date
        lastModified
        author
      }
    }
  }
}
`
	);
}
